import { isEmpty, get, isNumber, hasIn, has } from "lodash";
import { detectBrowser } from "./others.util";
import { BROWSERS } from "../definitions/browsers.definition";

export const getOffetFromUnitValue = (value) => {
  if (isNumber(value)) {
    return value;
  }

  return value.split(/(px|%|pt|em|[a-z]+)/)[0] || 0;
};

export const getStyleForPadding = (padding = {}, view = {}) => {
  return { ...padding };
};

export const getStyleForMargin = (margin = {}, view = {}) => {
  return { ...margin };
};

export const getStyleForHeight = (height = {}, view = {}) => {
  return { ...height };
};

export const getStyleForWidth = (width = {}, view = {}) => {
  let style = {};
  const browser = detectBrowser();

  if (isEmpty(width)) {
    return style;
  }

  if (width.fullWidth) {
    return {
      // width:
      //   browser === BROWSERS.CHROME
      //     ? "-webkit-fill-available"
      //     : browser === BROWSERS.FIREFOX
      //     ? "-moz-available"
      //     : "fill-available",
      width: "100%",
    };
  }

  const custom = getOffetFromUnitValue(width.custom);
  if (custom > view.width) {
    style = {
      width:
        browser === BROWSERS.CHROME
          ? "-webkit-fill-available"
          : browser === BROWSERS.FIREFOX
          ? "-moz-available"
          : "fill-available",
    };
  } else {
    style = { width: width.custom };
  }

  if (width.autoCenter) {
    style = { ...style, marginLeft: "auto", marginRight: "auto" };
  }

  return style;
};

export const getStyleForFormat = (format = {}, view = {}) => {
  return {
    ...getStyleForPadding(format.padding),
    ...getStyleForMargin(format.margin),
    ...getStyleForHeight(format.height),
    ...getStyleForWidth(format.width, view),
  };
};

export const getStyleForBorder = (border = {}, view = {}) => {
  return { ...border };
};

export const getStyleForBackground = (background = {}, view = {}) => {
  return isEmpty(background)
    ? {}
    : {
        backgroundColor: background.backgroundColor,
        ...(get(background, ["backgroundImage", "url"])
          ? {
              backgroundImage: `url('${get(background, [
                "backgroundImage",
                "url",
              ])}')`,
              backgroundPosition:
                get(background, ["backgroundImage", "position"]) || "center",
              backgroundSize:
                get(background, ["backgroundImage", "size"]) || "contain",
            }
          : {}),
      };
};

export const getStyleForShadow = (shadow = {}, view = {}) => {
  return isEmpty(shadow)
    ? {}
    : {
        boxShadow: `${shadow.horizontal || "0px"} ${shadow.vertical || "0px"} ${
          shadow.blur || "0px"
        } ${shadow.spread || "0px"} ${shadow.color || "transparent"}`,
      };
};

export const getStyleForSettings = {
  shadow: (shadow = {}, view = {}) => {
    return isEmpty(shadow)
      ? {}
      : {
          boxShadow: `${shadow.horizontal || "0px"} ${
            shadow.vertical || "0px"
          } ${shadow.blur || "0px"} ${shadow.spread || "0px"} ${
            shadow.color || "transparent"
          }`,
        };
  },
  background: (background = {}, view = {}) => {
    const style = {};

    if (isEmpty(background)) {
      return style;
    }

    if (hasIn(background, "backgroundColor")) {
      style.backgroundColor = background.backgroundColor;
    }

    if (hasIn(background, "backgroundImage")) {
      if (hasIn(background.backgroundImage, "url")) {
        style.backgroundImage = `url('${get(background, [
          "backgroundImage",
          "url",
        ])}')`;
      }

      if (hasIn(background.backgroundImage, "size")) {
        style.backgroundSize = background.backgroundImage.size;
      }

      if (hasIn(background.backgroundImage, "position")) {
        style.backgroundPosition = background.backgroundImage.position;
      }

      if (hasIn(background.backgroundImage, "repeat")) {
        style.backgroundRepeat = background.backgroundImage.repeat;
      }
    }

    return style;
  },
  border: (border = {}, view = {}) => {
    return {
      ...border,
    };
  },
  width: (width = {}, view = {}) => {
    let style = {};

    if (isEmpty(width)) {
      return style;
    }

    if (width.fullWidth) {
      return {
        width: "100%",
      };
    }

    if (width.isAuto) {
      return { width: "fit-content" };
    }

    const custom = getOffetFromUnitValue(width.custom);
    if (custom > view.width) {
      style = {
        width: "100%",
      };
    } else {
      style = { width: width.custom };
    }

    return style;
  },
  height: (height = {}, view = {}) => {
    if ("height" in height) {
      return { height: height.height || "auto" };
    } else if ("minHeight" in height) {
      return { minHeight: height.minHeight || "auto" };
    }
    return null;
  },
  padding: (padding = {}, view = {}) => {
    return { ...padding };
  },
  margin: (margin = {}, view = {}) => {
    return { ...margin };
  },
  text: (text = {}, view = {}) => {
    const style = {};

    text.color && (style.color = text.color);
    text.size && (style.fontSize = text.size);
    text.family && (style.fontFamily = text.family);
    text.style && (style.fontStyle = text.style);
    text.weight && (style.fontWeight = text.weight);
    text.alignment && (style.textAlign = text.alignment);
    text.letterSpacing && (style.letterSpacing = text.letterSpacing);
    text.lineHeight && (style.lineHeight = text.lineHeight);
    text.shadow &&
      (style.textShadow = `${text.shadow.horizontal} ${text.shadow.vertical} ${text.shadow.blur} ${text.shadow.color}`);

    return style;
  },
  contentAlignment: (contentAlignment = {}, view = {}) => {
    const style = {};

    if (isEmpty(contentAlignment)) {
      return style;
    }

    if (hasIn(contentAlignment, "horizontal")) {
      style.justifyContent = contentAlignment.horizontal;
    }

    if (hasIn(contentAlignment, "vertical")) {
      style.alignItems = contentAlignment.vertical;
    }

    return style;
  },
  alignment: (alignment = null, view = {}) => {
    const style = {};

    if (isEmpty(alignment)) {
      return style;
    }

    if (alignment === "left") {
      style.marginRight = "auto";
    }

    if (alignment === "right") {
      style.marginLeft = "auto";
    }

    if (alignment === "center") {
      style.marginLeft = "auto";
      style.marginRight = "auto";
    }

    return style;
  },
  addressLevelLabelDisplayLabel: (display, view = {}) => {
    return {
      ...(display === true ? {} : display === false ? { display: "none" } : {}),
    };
  },
  optionStyle: (optionStyle = {}, view = {}, exceptions = []) => {
    let style = {};

    for (const key in optionStyle) {
      if (exceptions.some((excep) => excep === key)) {
        continue;
      }

      if (getStyleForSettings[key]) {
        style = {
          ...style,
          ...getStyleForSettings[key](optionStyle[key], view),
        };
      }
    }

    return style;
    // return getStyleForSettings["text"](optionStyle.text, view);
  },
  selectBoxStyle: (selectBoxStyle = {}, view = {}, exceptions = []) => {
    let style = {};

    for (const key in selectBoxStyle) {
      if (exceptions.some((excep) => excep === key)) {
        continue;
      }

      if (getStyleForSettings[key]) {
        style = {
          ...style,
          ...getStyleForSettings[key](selectBoxStyle[key], view),
        };
      }
    }

    return style;
    // return {
    //   ...getStyleForSettings["border"](selectBoxStyle.border, view),
    //   ...getStyleForSettings["background"](selectBoxStyle.background, view),
    //   ...getStyleForSettings["width"](selectBoxStyle.width, view),
    //   ...getStyleForSettings["text"](selectBoxStyle.text, view),
    // };
  },
  buttonState: (stateStyle = {}, view = {}, exceptions = []) => {
    return {
      ...getStyleForSettings["text"](stateStyle.text, view),
      ...getStyleForSettings["border"](stateStyle.border, view),
      ...getStyleForSettings["shadow"](stateStyle.shadow, view),
      ...getStyleForSettings["background"](stateStyle.background, view),
    };
  },
  styleGroups: (groups = {}, view = {}, exceptions = []) => {
    let style = {};

    for (const key in groups) {
      if (exceptions.some((excep) => excep === key)) {
        continue;
      }

      if (getStyleForSettings[key]) {
        style = {
          ...style,
          ...getStyleForSettings[key](groups[key], view),
        };
      }
    }

    return style;
  },
};
