import React from "react";
import { get } from "lodash";
import styles from "./label.styles.scss";
import { getDefaultView } from "../../utils/others.util";
import { getStyleForSettings } from "../../utils/style.util";

export function BSFormObjectLabel({
  style = {},
  className = "",
  view = getDefaultView(),
  wrapperComponent = null,
  object,
  objectPath,
  throughProps = {},
  onObjectData = null,
  onSignalSubmit = null,
  onValidData = null,
  ...props
}) {
  const settings = object.settings || {};
  const WrapperComponent = wrapperComponent;
  const wrapperStyle = {
    ...getStyleForSettings["styleGroups"](settings, view),
    // ...getStyleForSettings["margin"](settings.margin, view),
    // ...getStyleForSettings["text"](settings.text, view),
    ...(style || {}),
  };

  return WrapperComponent ? (
    <WrapperComponent
      object={object}
      objectPath={objectPath}
      className={`${styles.container} ${className || ""} ${get(settings, "customClassName") || ""}`}
      style={{ width: "100%", ...wrapperStyle }}
      {...props}
    >
      {get(settings, ["labelMore", "label"]) || ""}
    </WrapperComponent>
  ) : (
    <div className={`${styles.container} ${className || ""} ${get(settings, "customClassName") || ""}`} style={wrapperStyle} {...props}>
      {get(settings, ["labelMore", "label"]) || ""}
    </div>
  );
}
