import React, { useState, useEffect, useMemo } from "react";
import { get, pick, omit } from "lodash";
import { Radio } from "antd";
import { checkValidFormObjectData } from "../../utils/form-object.util";
import { getStyleForSettings } from "../../utils/style.util";
import styles from "./radio-group.styles.scss";

export function BSFormObjectRadioGroup({
  style = {},
  className = "",
  view = {},
  object,
  objectPath,
  wrapperComponent = null,
  throughProps = {},
  onObjectData = null,
  onSignalSubmit = null,
  onValidData = null,
  ...props
}) {
  const customField = useMemo(() => get(object, ["settings", "customField"]), [
    get(object, ["settings", "customField"]),
  ]);
  const [type] = useState(get(object, "type"));
  const [value, setValue] = useState(
    get(object, ["settings", "radioGroupMore", "default"]) || null
  );
  const valid = useMemo(() => {
    return checkValidFormObjectData(type)(value, object);
  }, [object, get(object, ["settings", "radioGroupMore", "required"]), value]);

  const WrapperComponent = wrapperComponent;
  const settings = get(object, "settings") || {};

  const onChangeValue = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    customField && onObjectData && onObjectData({ [customField]: value });
  }, [value]);

  useEffect(() => {
    customField && onValidData && onValidData({ [customField]: valid });
  }, [valid]);

  return WrapperComponent ? (
    <WrapperComponent
      object={object}
      objectPath={objectPath}
      className={`${className || ""}`}
      style={{
        ...getStyleForSettings["styleGroups"](
          pick(settings, ["margin", "alignment"]),
          view
        ),
        width: "auto",
        ...pick(style || {}, [
          "margin",
          "marginTop",
          "marginBottom",
          "marginLeft",
          "marginRight",
        ]),
      }}
    >
      <Radio.Group
        className={`${get(settings, ["customClassName"]) || ""}`}
        style={{
          ...getStyleForSettings["styleGroups"](
            omit(settings, ["margin", "alignment"]),
            view
          ),
          ...(get(settings, ["radioGroupMore", "optionsDirection"]) ===
          "horizontal"
            ? {}
            : { display: "flex", flexDirection: "column" }),
          ...omit(style || {}, [
            "margin",
            "marginTop",
            "marginBottom",
            "marginLeft",
            "marginRight",
          ]),
        }}
        value={value}
        onChange={onChangeValue}
        {...props}
      >
        {(get(settings, ["radioGroupMore", "options"]) || []).map((option) => (
          <Radio
            key={option.value}
            value={option.value}
            className={`${valid ? "" : styles.invalid}`}
            style={{
              ...getStyleForSettings["optionStyle"](
                settings.radioGroupOptionStyle,
                view
              ),
            }}
          >
            {option.label}
          </Radio>
        ))}
      </Radio.Group>
    </WrapperComponent>
  ) : (
    <Radio.Group
      className={`${className || ""} ${
        get(settings, ["customClassName"]) || ""
      }`}
      style={{
        ...getStyleForSettings["styleGroups"](settings, view),
        ...(get(settings, ["radioGroupMore", "optionsDirection"]) ===
        "horizontal"
          ? {}
          : { display: "flex", flexDirection: "column" }),
        ...(style || {}),
      }}
      value={value}
      onChange={onChangeValue}
      {...props}
    >
      {(get(settings, ["radioGroupMore", "options"]) || []).map((option) => (
        <Radio
          key={option.value}
          value={option.value}
          className={`${valid ? "" : styles.invalid}`}
          style={{
            ...getStyleForSettings["optionStyle"](
              settings.radioGroupOptionStyle,
              view
            ),
          }}
        >
          {option.label}
        </Radio>
      ))}
    </Radio.Group>
  );
}
