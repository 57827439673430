import React, { useState, useCallback, useEffect, useMemo } from "react";
import { DynamicLevelLocationSelector as CountryRegionSelector } from "country-region-selector";
import { get, isEmpty, pick } from "lodash";
import { getStyleForSettings } from "../../utils/style.util";
import { checkValidFormObjectData } from "../../utils/form-object.util";
import { getDefaultView } from "../../utils/others.util";

import styles from "./address.styles.scss";

export function BSFormObjectAddress({
  style = {},
  className = "",
  view = getDefaultView(),
  object,
  objectPath,
  wrapperComponent = null,
  throughProps = {},
  onObjectData = null,
  onSignalSubmit = null,
  onValidData = null,
  ...props
}) {
  const customFields = useMemo(
    () => get(object, ["settings", "addressMore", "customFields"]) || {},
    [get(object, ["settings", "addressMore", "customFields"])]
  );
  const [type] = useState(get(object, "type"));
  const [address, setAddress] = useState(() => {
    if (get(object, ["settings", "addressMore", "default"])) {
      return get(object, ["settings", "addressMore", "default"]);
    }

    const addressValue = {
      [customFields.addressDetail]: null,
    };

    (get(customFields, ["addressLevels"]) || []).forEach((cf) => (addressValue[cf] = null));

    return addressValue;
  });
  const valid = useMemo(() => {
    return checkValidFormObjectData(type)(address, object);
  }, [object, get(object, ["settings", "addressMore", "required"]), address]);
  const [customLayout] = useState("vertical");

  const settings = get(object, "settings") || {};
  const WrapperComponent = wrapperComponent;

  const onChangeAddress = (data) => {
    setAddress((previous) => {
      const newAddress = {
        ...previous,
      };

      (get(customFields, ["addressLevels"]) || []).forEach((cf, index) => {
        newAddress[cf] = data.value[index] || null;
      });

      return newAddress;
    });
  };

  const onChangeAddressDetail = (e) => {
    const detail = e.target.value;

    setAddress((previous) => {
      const newAddress = {
        ...previous,
        [customFields.addressDetail]: detail,
      };

      return newAddress;
    });
  };

  useEffect(() => {
    if (!isEmpty(customFields.addressDetail) && !isEmpty(customFields.addressLevels) && onObjectData) {
      const addressToSend = {
        [customFields.addressDetail]: address[customFields.addressDetail],
      };

      for (const cf of customFields.addressLevels) {
        addressToSend[cf] = get(address[cf], "name") || null;
      }
      onObjectData(addressToSend);
    }
  }, [address]);

  useEffect(() => {
    !isEmpty(customFields.addressDetail) &&
      !isEmpty(customFields.addressLevels) &&
      onValidData &&
      onValidData({
        ...(valid || {}),
      });
  }, [valid]);

  const addressLevelSelectorStyle = {
    ...getStyleForSettings["styleGroups"](get(settings, "addressLevelSelector"), view),
  };
  const addressLevelLabelStyle = {
    ...getStyleForSettings["styleGroups"](get(settings, "addressLevelLabel"), view),
  };
  const addressDetailStyle = {
    ...getStyleForSettings["styleGroups"](get(settings, "addressDetail"), view),
  };

  return WrapperComponent ? (
    <WrapperComponent
      object={object}
      objectPath={objectPath}
      className={`${className || ""} ${get(settings, "customClassName") || ""}`}
      style={{
        ...getStyleForSettings["styleGroups"](pick(settings, ["padding", "margin"]), view),
        ...(style || {}),
        width: "100%",
      }}
      {...props}
    >
      <input
        className={`${styles.detail} ${get(valid, customFields.addressDetail) ? "" : styles.inValid}`}
        placeholder={get(settings, ["addressMore", "placeholders", "addressDetail"]) || ""}
        style={addressDetailStyle}
        onChange={onChangeAddressDetail}
      />
      <CountryRegionSelector
        countryCode={get(settings, ["addressMore", "countryCode"]) || ""}
        value={(get(settings, ["addressMore", "customFields", "addressLevels"]) || []).map((cf) => address[cf] || { name: null, code: null })}
        customLayout={customLayout}
        componentLevels={{
          level0: {
            customLabel: addressLevelLabelStyle,
            customSelector: {
              ...addressLevelSelectorStyle,
              placeholder: (get(settings, ["addressMore", "placeholders", "addressLevels"]) || [])[0],
            },
          },
          level1: {
            customLabel: addressLevelLabelStyle,
            customSelector: {
              ...addressLevelSelectorStyle,
              placeholder: (get(settings, ["addressMore", "placeholders", "addressLevels"]) || [])[1],
            },
          },
          level2: {
            customLabel: addressLevelLabelStyle,
            customSelector: {
              ...addressLevelSelectorStyle,
              placeholder: (get(settings, ["addressMore", "placeholders", "addressLevels"]) || [])[2],
            },
          },
          level3: {
            customLabel: addressLevelLabelStyle,
            customSelector: {
              ...addressLevelSelectorStyle,
              placeholder: (get(settings, ["addressMore", "placeholders", "addressLevels"]) || [])[3],
            },
          },
          level4: {
            customLabel: addressLevelLabelStyle,
            customSelector: {
              ...addressLevelSelectorStyle,
              placeholder: (get(settings, ["addressMore", "placeholders", "addressLevels"]) || [])[4],
            },
          },
          level5: {
            customLabel: addressLevelLabelStyle,
            customSelector: {
              ...addressLevelSelectorStyle,
              placeholder: (get(settings, ["addressMore", "placeholders", "addressLevels"]) || [])[5],
            },
          },
        }}
        onChange={onChangeAddress}
      />
    </WrapperComponent>
  ) : (
    <div
      className={`${className || ""} ${get(settings, "customClassName") || ""}`}
      style={{
        ...getStyleForSettings["margin"](settings.margin, view),
        ...(style || {}),
        width: "100%",
      }}
      {...props}
    >
      <input
        className={`${styles.detail} ${get(valid, customFields.addressDetail) ? "" : styles.inValid}`}
        style={{
          ...getStyleForSettings["styleGroups"](get(settings, "addressDetail"), view),
        }}
        onChange={onChangeAddressDetail}
        placeholder={get(settings, ["addressMore", "placeholders", "addressDetail"]) || ""}
      />
      <CountryRegionSelector
        countryCode={get(settings, ["addressMore", "countryCode"]) || ""}
        value={(get(settings, ["addressMore", "customFields", "addressLevels"]) || []).map((cf) => address[cf] || { name: null, code: null })}
        customLayout={customLayout}
        componentLevels={{
          level0: {
            customLabel: addressLevelLabelStyle,
            customSelector: {
              ...addressLevelSelectorStyle,
              placeholder: (get(settings, ["addressMore", "placeholders", "addressLevels"]) || [])[0],
            },
          },
          level1: {
            customLabel: addressLevelLabelStyle,
            customSelector: {
              ...addressLevelSelectorStyle,
              placeholder: (get(settings, ["addressMore", "placeholders", "addressLevels"]) || [])[1],
            },
          },
          level2: {
            customLabel: addressLevelLabelStyle,
            customSelector: {
              ...addressLevelSelectorStyle,
              placeholder: (get(settings, ["addressMore", "placeholders", "addressLevels"]) || [])[2],
            },
          },
          level3: {
            customLabel: addressLevelLabelStyle,
            customSelector: {
              ...addressLevelSelectorStyle,
              placeholder: (get(settings, ["addressMore", "placeholders", "addressLevels"]) || [])[3],
            },
          },
          level4: {
            customLabel: addressLevelLabelStyle,
            customSelector: {
              ...addressLevelSelectorStyle,
              placeholder: (get(settings, ["addressMore", "placeholders", "addressLevels"]) || [])[4],
            },
          },
          level5: {
            customLabel: addressLevelLabelStyle,
            customSelector: {
              ...addressLevelSelectorStyle,
              placeholder: (get(settings, ["addressMore", "placeholders", "addressLevels"]) || [])[5],
            },
          },
        }}
        onChange={onChangeAddress}
      />
    </div>
  );
}
