import { get, pick } from "lodash";
import React, { useState, useEffect } from "react";
import styles from "./button.styles.scss";
import { getDefaultView } from "../../utils/others.util";
import { getStyleForSettings } from "../../utils/style.util";

export function BSFormObjectButton({
  style = {},
  className = "",
  view = getDefaultView(),
  object,
  objectPath,
  wrapperComponent = null,
  throughProps = {},
  onObjectData = null,
  onSignalSubmit = null,
  onValidData = null,
  ...props
}) {
  const [styleFollowingEvents, setStyleFollowingEvents] = useState({});
  const [actived, setActived] = useState(false);
  const settings = get(object, "settings") || {};
  const normalStyle = getStyleForSettings["styleGroups"](get(settings, ["buttonStates", "normal"]), view);
  const hoverStyle = getStyleForSettings["styleGroups"](get(settings, ["buttonStates", "hover"]), view);
  const activeStyle = getStyleForSettings["styleGroups"](get(settings, ["buttonStates", "active"]), view);
  const wrapperStyle = {
    ...getStyleForSettings["styleGroups"](pick(settings, ["padding", "margin", "height", "width", "alignment", "text", "contentAlignment"])),
    display: "flex",
    alignItems: "center",
    justContent: "center",
    ...(style || {}),
  };
  const WrapperComponent = wrapperComponent;

  useEffect(() => {
    setStyleFollowingEvents(normalStyle);
  }, []);

  return WrapperComponent ? (
    <WrapperComponent
      object={object}
      objectPath={objectPath}
      className={`${styles.container} ${className || ""} ${get(settings, "customClassName") || ""}`}
      style={{
        ...wrapperStyle,
        ...styleFollowingEvents,
      }}
      onMouseEnter={() =>
        setStyleFollowingEvents({
          ...hoverStyle,
        })
      }
      onMouseLeave={() =>
        setStyleFollowingEvents({
          ...(actived ? activeStyle : normalStyle),
        })
      }
      onClick={onSignalSubmit}
      {...props}
    >
      {get(settings, ["buttonMore", "label"])}
    </WrapperComponent>
  ) : (
    <div
      className={`${styles.container} ${className || ""} ${get(settings, "customClassName") || ""}`}
      style={{
        ...wrapperStyle,
        ...styleFollowingEvents,
      }}
      onMouseEnter={() =>
        setStyleFollowingEvents({
          ...hoverStyle,
        })
      }
      onMouseLeave={() =>
        setStyleFollowingEvents({
          ...(actived ? activeStyle : normalStyle),
        })
      }
      onClick={onSignalSubmit}
      {...props}
    >
      {get(settings, ["buttonMore", "label"])}
    </div>
  );
}
