import React, { useState, useEffect, useMemo } from "react";
import { get, pick, omit } from "lodash";
import { checkValidFormObjectData } from "../../utils/form-object.util";
import { getStyleForSettings } from "../../utils/style.util";
import styles from "./checkbox-styles.scss";
import { Checkbox } from "antd";
import "./overwrite.css";
export function BSFormObjectCheckbox({
  style = {},
  className = "",
  view = {},
  object,
  objectPath,
  wrapperComponent = null,
  throughProps = {},
  onObjectData = null,
  onSignalSubmit = null,
  onValidData = null,
  ...props
}) {
  const customField = useMemo(() => get(object, ["settings", "customField"]), [get(object, ["settings", "customField"])]);
  const [type] = useState(get(object, "type"));
  const [value, setValue] = useState(get(object, ["settings", "checkboxGroupMore", "default"]) || []);
  const valid = useMemo(() => {
    return checkValidFormObjectData(type)(value, object);
  }, [object, get(object, ["settings", "checkboxGroupMore", "required"]), value]);

  const WrapperComponent = wrapperComponent;
  const settings = get(object, "settings") || {};
  console.log("settingsCheckbox", settings);
  const onChangeValue = e => {
    setValue(e);
  };

  useEffect(() => {
    customField && onObjectData && onObjectData({ [customField]: value });
  }, [value]);

  useEffect(() => {
    customField && onValidData && onValidData({ [customField]: valid });
  }, [valid]);

  return WrapperComponent ? (
    <WrapperComponent
      object={object}
      objectPath={objectPath}
      className={`${className || ""}`}
      style={{
        ...getStyleForSettings["styleGroups"](pick(settings, ["margin", "alignment"]), view),
        width: "auto",
        ...pick(style || {}, ["margin", "marginTop", "marginBottom", "marginLeft", "marginRight"])
      }}
    >
      <Checkbox.Group
        className={`bs-form-object-components-checkbox ${get(settings, ["customClassName"]) || ""}`}
        style={{
          ...getStyleForSettings["styleGroups"](omit(settings, ["margin", "alignment"]), view),
          ...(get(settings, ["checkboxGroupMore", "optionsDirection"]) === "horizontal" ? {} : { display: "flex", flexDirection: "column" }),
          ...omit(style || {}, ["margin", "marginTop", "marginBottom", "marginLeft", "marginRight"])
        }}
        value={value}
        onChange={onChangeValue}
        {...props}
      >
        {(get(settings, ["checkboxGroupMore", "options"]) || []).map(option => {
          return (
            <Checkbox
              key={option.value}
              value={option.value}
              className={`${valid ? "" : styles.invalid}`}
              style={{
                ...getStyleForSettings["optionStyle"](settings.checkboxGroupOptionStyle, view)
              }}
            >
              {option.label}
            </Checkbox>
          );
        })}
      </Checkbox.Group>
    </WrapperComponent>
  ) : (
    <Checkbox.Group
      className={`bs-form-object-components-checkbox ${get(settings, ["customClassName"]) || ""}`}
      style={{
        ...getStyleForSettings["styleGroups"](omit(settings, ["margin", "alignment"]), view),
        ...(get(settings, ["checkboxGroupMore", "optionsDirection"]) === "horizontal" ? {} : { display: "flex", flexDirection: "column" }),
        ...omit(style || {}, ["margin", "marginTop", "marginBottom", "marginLeft", "marginRight"])
      }}
      value={value}
      onChange={onChangeValue}
      {...props}
    >
      {(get(settings, ["checkboxGroupMore", "options"]) || []).map(option => {
        return (
          <Checkbox
            key={option.value}
            value={option.value}
            className={`${valid ? "" : styles.invalid}`}
            style={{
              ...getStyleForSettings["optionStyle"](settings.checkboxGroupOptionStyle, view)
            }}
          >
            {option.label}
          </Checkbox>
        );
      })}
    </Checkbox.Group>
  );
}
