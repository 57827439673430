import React from "react";
import { get } from "lodash";
import styles from "./styles.scss";
import { getDefaultView } from "../../utils/others.util";
import { getStyleForSettings } from "../../utils/style.util";

export function BsFormObjectImage({
  style = {},
  className = "",
  view = getDefaultView(),
  throughProps = {},
  object,
  objectPath = [],
  wrapperComponent = null,
  onObjectData = null,
  onSignalSubmit = null,
  onValidData = null,
  ...props
}) {
  const settings = get(object, "settings") || {};
  const WrapperComponent = wrapperComponent;

  const wrapperStyle = {
    ...getStyleForSettings["styleGroups"](settings, view),
    ...(style || {}),
  };

  return wrapperComponent ? (
    <WrapperComponent
      className={`${className || ""} ${
        get(object, ["settings", "customClassName"]) || ""
      }`}
      object={object}
      objectPath={objectPath}
      {...props}
      style={{
        ...wrapperStyle,
        overflow: "hidden",
      }}
    >
      {/* <div
        className={`${styles.container} ${className || ""} ${
          get(object, ["settings", "customClassName"]) || ""
        }`}
        style={{ ...wrapperStyle, display: "flex", overflow: "hidden" }}
      > */}
      {!get(object, ["settings", "image", "url"]) ? (
        <div className={styles.imageHolder}>
          <i className={styles.icon}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="33.325"
              height="23.65"
              viewBox="0 0 33.325 23.65"
            >
              <path
                d="M495.187 312.875v23.65h33.325v-23.65zM526.326 315v12.429l-5.754-5.652-6.968 4.889-6.253-8.556-9.98 7.916V315zm-28.955 19.4v-5.624l9.552-7.576 6.175 8.45 7.236-5.077 5.987 5.885v3.949z"
                style={{ fill: "#5552d5" }}
                transform="translate(-495.187 -312.875)"
              />
              <circle
                cx="2.196"
                cy="2.196"
                r="2.196"
                style={{ fill: "#5552d5" }}
                transform="translate(16.953 3.828)"
              />
            </svg>
          </i>
          <div className={styles.desc}>
            <div style={{ color: "#43c0c4" }}>+ Upload image</div>
            <span style={{ color: "#999999", margin: "0 5px" }}>or</span>
            <div style={{ color: "#5552d5" }}>paste an URL</div>
          </div>
        </div>
      ) : (
        <img
          src={get(object, ["settings", "image", "url"])}
          style={{ width: "100%", height: "100%" }}
        />
      )}
      {/* </div> */}
    </WrapperComponent>
  ) : (
    <div
      className={`${styles.container} ${className || ""} ${
        get(object, ["settings", "customClassName"]) || ""
      }`}
      style={{
        ...wrapperStyle,
        display: "block",
        overflow: "hidden",
      }}
    >
      {!get(object, ["settings", "image", "url"]) ? (
        <div
          className={styles.imageHolder}
          style={{ width: "100%", height: "100%" }}
        >
          <i className={styles.icon}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="33.325"
              height="23.65"
              viewBox="0 0 33.325 23.65"
            >
              <path
                d="M495.187 312.875v23.65h33.325v-23.65zM526.326 315v12.429l-5.754-5.652-6.968 4.889-6.253-8.556-9.98 7.916V315zm-28.955 19.4v-5.624l9.552-7.576 6.175 8.45 7.236-5.077 5.987 5.885v3.949z"
                style={{ fill: "#5552d5" }}
                transform="translate(-495.187 -312.875)"
              />
              <circle
                cx="2.196"
                cy="2.196"
                r="2.196"
                style={{ fill: "#5552d5" }}
                transform="translate(16.953 3.828)"
              />
            </svg>
          </i>
          <div className={styles.desc}>
            <div style={{ color: "#43c0c4" }}>+ Upload image</div>
            <span style={{ color: "#999999", margin: "0 5px" }}>or</span>
            <div style={{ color: "#5552d5" }}>paste an URL</div>
          </div>
        </div>
      ) : (
        <img
          src={get(object, ["settings", "image", "url"])}
          style={{ width: "100%", height: "100%" }}
        />
      )}
    </div>
  );
}
