import React from "react";
import { get, omit } from "lodash";
import styles from "./styles.scss";
import { getDefaultView } from "../../utils/others.util";
import { getStyleForSettings } from "../../utils/style.util";

export function BsFormObjectVideo({
  style = {},
  className = "",
  view = getDefaultView(),
  throughProps = {},
  object,
  objectPath = [],
  wrapperComponent = null,
  onObjectData = null,
  onSignalSubmit = null,
  onValidData = null,
  ...props
}) {
  const settings = get(object, "settings") || {};
  const WrapperComponent = wrapperComponent;

  const wrapperStyle = {
    ...getStyleForSettings["styleGroups"](settings, view),
    ...(style || {}),
  };

  const genSrc = (url) => {
    try {
      const myUrl = new URL(url);
      const searchParams = new URLSearchParams(myUrl.search.slice(1));
      const videoId = searchParams.get("v");
      const { mute, loop, autoPlay } = get(object, [
        "settings",
        "video",
        "play",
      ]);
      let ytUrl = `https://www.youtube.com/embed/${videoId}?autoplay=${Number(
        autoPlay
      )}&mute=${Number(mute)}`;
      if (loop) {
        ytUrl = `https://www.youtube.com/embed/${videoId}?autoplay=${Number(
          autoPlay
        )}&loop=${Number(loop)}&mute=${Number(mute)}&playlist=${videoId}`;
      }
      ytUrl += "&showinfo=0&rel=0";
      return ytUrl;
    } catch (err) {
      console.log(err);
      return "";
    }
  };

  const genPaddingTop = () => {
    const [num1, num2] = get(object, ["settings", "video", "aspect"]).split(
      ":"
    );
    if (!get(object, ["settings", "video", "url"])) {
      const paddingTop = get(object, ["settings", "padding", "paddingTop"]);
      const [num] = paddingTop.match(/\d+/);
      return `${parseInt(num)}px`;
    }
    return `${(parseInt(num2) / parseInt(num1)) * 100}%`;
  };

  return wrapperComponent ? (
    <WrapperComponent
      object={object}
      objectPath={objectPath}
      style={{
        ...wrapperStyle,
      }}
      {...props}
    >
      <div
        className={`${styles.container} ${className || ""} ${
          get(object, ["settings", "customClassName"]) || ""
        }`}
        style={{
          width: "100%",
          height: "100%",
        }}
        {...props}
      >
        {!get(object, ["settings", "video", "url"]) ? (
          <div className={styles.imageHolder}>
            <i className={styles.icon}>
              <svg
                width="33.325"
                height="23.65"
                viewBox="0 -61 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m75 390h362c41.355469 0 75-33.644531 75-75v-240c0-41.355469-33.644531-75-75-75h-362c-41.355469 0-75 33.644531-75 75v240c0 41.355469 33.644531 75 75 75zm-45-315c0-24.8125 20.1875-45 45-45h362c24.8125 0 45 20.1875 45 45v240c0 24.8125-20.1875 45-45 45h-362c-24.8125 0-45-20.1875-45-45zm0 0"
                  style={{ fill: "#5552d5" }}
                />
                <path
                  d="m181 94.507812v203.898438l180.5-103.621094zm30 50.984376 89.5 49.722656-89.5 51.378906zm0 0"
                  style={{ fill: "#5552d5" }}
                />
              </svg>
            </i>
            <div style={{ color: "#5552d5", textAlign: "center" }}>
              Youtube video link
            </div>
          </div>
        ) : (
          <iframe
            width="100%"
            height="100%"
            src={genSrc(get(object, ["settings", "video", "url"]))}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}
      </div>
    </WrapperComponent>
  ) : (
    <div
      className={`${styles.container} ${className || ""} ${
        get(object, ["settings", "customClassName"]) || ""
      }`}
      style={{
        ...wrapperStyle,
        width: "100%",
        height: "100%",
        position: "relative",
        paddingTop: genPaddingTop(),
      }}
      {...props}
    >
      {!get(object, ["settings", "video", "url"]) ? (
        <div className={styles.imageHolder}>
          <i className={styles.icon}>
            <svg
              width="33.325"
              height="23.65"
              viewBox="0 -61 512 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m75 390h362c41.355469 0 75-33.644531 75-75v-240c0-41.355469-33.644531-75-75-75h-362c-41.355469 0-75 33.644531-75 75v240c0 41.355469 33.644531 75 75 75zm-45-315c0-24.8125 20.1875-45 45-45h362c24.8125 0 45 20.1875 45 45v240c0 24.8125-20.1875 45-45 45h-362c-24.8125 0-45-20.1875-45-45zm0 0"
                style={{ fill: "#5552d5" }}
              />
              <path
                d="m181 94.507812v203.898438l180.5-103.621094zm30 50.984376 89.5 49.722656-89.5 51.378906zm0 0"
                style={{ fill: "#5552d5" }}
              />
            </svg>
          </i>
          <div style={{ color: "#5552d5", textAlign: "center" }}>
            Youtube video link
          </div>
        </div>
      ) : (
        <iframe
          width="100%"
          height="100%"
          style={{ position: "absolute", top: 0, left: 0 }}
          src={genSrc(get(object, ["settings", "video", "url"]))}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      )}
    </div>
  );
}
