import React, { useState, useEffect, useMemo } from "react";
import { get, pick, omit } from "lodash";
import styles from "./input.styles.scss";
import { checkValidFormObjectData } from "../../utils/form-object.util";
import { getStyleForSettings } from "../../utils/style.util";

export function BSFormObjectInput({
  style = {},
  className = "",
  view = getDefaultView(),
  object,
  objectPath,
  wrapperComponent = null,
  throughProps = {},
  onObjectData = null,
  onSignalSubmit = null,
  onValidData = null,
  ...props
}) {
  const customField = useMemo(() => get(object, ["settings", "customField"]), [
    get(object, ["settings", "customField"]),
  ]);
  const [type] = useState(get(object, "type"));
  const [text, setText] = useState(
    get(object, ["settings", "inputMore", "default"]) || ""
  );
  const valid = useMemo(() => {
    return checkValidFormObjectData(type)(text, object);
  }, [
    object,
    get(object, ["settings", "inputMore", "required"]),
    get(object, ["settings", "inputMore", "inputType"]),
    text,
  ]);

  const settings = get(object, "settings") || {};
  const WrapperComponent = wrapperComponent;

  const onChangeText = (e) => {
    const newText = e.target.value;

    setText(newText);
  };

  useEffect(() => {
    customField &&
      onObjectData &&
      onObjectData({
        [customField]: text,
      });
  }, [text]);

  useEffect(() => {
    customField &&
      onValidData &&
      onValidData({
        [customField]: valid,
      });
  }, [valid]);

  return wrapperComponent ? (
    <WrapperComponent
      object={object}
      objectPath={objectPath}
      className={`${className || ""}`}
      style={{
        ...getStyleForSettings["margin"](settings.margin, view),
        ...getStyleForSettings["width"](settings.width, view),
        ...pick(style || {}, [
          "margin",
          "marginLeft",
          "marginRight",
          "marginTop",
          "marginBottom",
          "width",
          "minWidth",
          "maxWidth",
        ]),
      }}
    >
      <input
        className={`${styles.input} ${get(settings, "customClassName") || ""} ${
          !valid ? styles.validError : ""
        }`}
        type={get(settings, ["inputMore", "inputType"])}
        name={get(settings, ["customField"])}
        placeholder={get(settings, ["inputMore", "placeholder"]) || ""}
        value={text}
        onChange={onChangeText}
        style={{
          ...getStyleForSettings["styleGroups"](
            omit(settings, ["width", "margin"]),
            view
          ),
          width: "100%",
          ...omit(style || {}, [
            "margin",
            "marginLeft",
            "marginRight",
            "marginTop",
            "marginBottom",
            "width",
            "minWidth",
            "maxWidth",
          ]),
        }}
        {...props}
      />
    </WrapperComponent>
  ) : (
    <input
      className={`${styles.input} ${className || ""} ${
        get(settings, "customClassName") || ""
      } ${!valid ? styles.validError : ""}`}
      type={get(settings, ["inputMore", "inputType"])}
      name={get(settings, ["customField"])}
      placeholder={get(settings, ["inputMore", "placeholder"]) || ""}
      value={text}
      onChange={onChangeText}
      style={{
        ...getStyleForSettings["styleGroups"](settings, view),
        ...(style || {}),
      }}
      {...props}
    />
  );
}
