import { get, flattenDeep, isEmpty, isNull, isUndefined } from "lodash";
import { getListLevel } from "country-region-selector";
import { FORM_OBJECT_TYPES } from "../definitions/form-object-types.definition";
import { FORM_OBJECT_TYPES_CAN_HAVE_USER_VALUE } from "../definitions/form-object-types.definition";
import { INPUT_TYPES } from "../definitions/input-types.definition";

export const formObjectGenerators = {
  [FORM_OBJECT_TYPES.CONTAINER]: (id) => ({
    id,
    name: "container",
    type: FORM_OBJECT_TYPES.CONTAINER,
    settings: {
      columns: {
        counts: 1,
        spacing: 0,
        equalColumnsWidth: true,
        customColumnsWidth: ["100%"],
      },
      width: {
        fullWidth: true,
        isAuto: false,
        custom: "0px",
      },
      height: {
        minHeight: "50px",
      },
      margin: {
        marginTop: "0px",
        marginBottom: "0px",
        marginLeft: "0px",
        marginRight: "0px",
      },
      padding: {
        paddingTop: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        paddingRight: "0px",
      },
      border: {
        borderWidth: "0px",
        borderColor: "#000",
        borderStyle: "solid",
        borderRadius: "0px",
      },
      background: {
        backgroundColor: "#fff",
        backgroundImage: {
          url: null,
          size: "contain",
          position: "left top",
          repeat: "no-repeat",
        },
      },
      shadow: {
        horizontal: "0px",
        vertical: "0px",
        blur: "0px",
        spread: "0px",
        color: "#000",
      },
      customClassName: null,
    },
    objects: [],
  }),
  [FORM_OBJECT_TYPES.CELL]: (id) => ({
    id,
    name: "cell",
    type: FORM_OBJECT_TYPES.CELL,
    settings: {
      height: {
        minHeight: "50px",
      },
      margin: {
        marginTop: "0px",
        marginBottom: "0px",
        marginLeft: "0px",
        marginRight: "0px",
      },
      padding: {
        paddingTop: "5px",
        paddingBottom: "5px",
        paddingLeft: "5px",
        paddingRight: "5px",
      },
      border: {
        borderWidth: "0px",
        borderColor: "#000",
        borderStyle: "solid",
        borderRadius: "0px",
      },
      background: {
        backgroundColor: "#fff",
        backgroundImage: {
          url: null,
          size: "contain",
          position: "left top",
          repeat: "no-repeat",
        },
      },
      shadow: {
        horizontal: "0px",
        vertical: "0px",
        blur: "0px",
        spread: "0px",
        color: "#000",
      },
      contentAlignment: {
        horizontal: "flex-start",
        vertical: "flex-start",
      },
      customClassName: null,
    },
    objects: [],
  }),
  [FORM_OBJECT_TYPES.BUTTON]: (id) => ({
    id,
    name: "button",
    type: FORM_OBJECT_TYPES.BUTTON,
    settings: {
      width: {
        fullWidth: false,
        isAuto: true,
        custom: "0px",
      },
      height: {
        minHeight: "50px",
      },
      margin: {
        marginTop: "0px",
        marginBottom: "0px",
      },
      padding: {
        paddingTop: "5px",
        paddingBottom: "5px",
        paddingLeft: "10px",
        paddingRight: "10px",
      },
      alignment: "center",
      contentAlignment: {
        horizontal: "center",
        vertical: "center",
      },
      text: {
        family: "Roboto",
        size: "14px",
        weight: "normal",
        style: "normal",
      },
      buttonStates: {
        normal: {
          text: {
            color: "#fff",
          },
          border: {
            borderWidth: "0px",
            borderColor: "#000",
            borderStyle: "solid",
            borderRadius: "0px",
          },
          shadow: {
            horizontal: "0px",
            vertical: "2px",
            blur: "0px",
            spread: "0px",
            color: "rgba(0,0,0,0.045)",
          },
          background: {
            backgroundColor: "#1890ff",
            backgroundImage: {
              url: null,
              size: "contain",
              position: "left top",
              repeat: "no-repeat",
            },
          },
        },
        hover: {
          text: {
            color: "#fff",
          },
          border: {
            borderWidth: "0px",
            borderColor: "#000",
            borderStyle: "solid",
            borderRadius: "0px",
          },
          shadow: {
            horizontal: "0px",
            vertical: "2px",
            blur: "0px",
            spread: "0px",
            color: "rgba(0,0,0,0.045)",
          },
          background: {
            backgroundColor: "#40a9ff",
            backgroundImage: {
              url: null,
              size: "contain",
              position: "left top",
              repeat: "no-repeat",
            },
          },
        },
        active: {
          text: {
            color: "#fff",
          },
          border: {
            borderWidth: "0px",
            borderColor: "#000",
            borderStyle: "solid",
            borderRadius: "0px",
          },
          shadow: {
            horizontal: "0px",
            vertical: "2px",
            blur: "0px",
            spread: "0px",
            color: "rgba(0,0,0,0.045)",
          },
          background: {
            backgroundColor: "#40a9ff",
            backgroundImage: {
              url: null,
              size: "contain",
              position: "left top",
              repeat: "no-repeat",
            },
          },
        },
      },
      buttonMore: {
        label: "Button",
      },
      customClassName: null,
    },
    objects: [],
  }),
  [FORM_OBJECT_TYPES.LABEL]: (id) => ({
    id,
    name: "label",
    type: FORM_OBJECT_TYPES.LABEL,
    settings: {
      margin: {
        marginTop: "5px",
        marginBottom: "5px",
        marginLeft: "0px",
        marginRight: "0px",
      },
      padding: {
        paddingTop: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        paddingRight: "0px",
      },
      text: {
        family: "Roboto",
        size: "14px",
        color: "#000",
        alignment: "left",
        weight: "normal",
        style: "normal",
      },
      labelMore: { label: "Label" },
      customClassName: null,
    },
    objects: [],
  }),
  [FORM_OBJECT_TYPES.INPUT]: (id) => ({
    id,
    name: "input",
    type: FORM_OBJECT_TYPES.INPUT,
    settings: {
      width: {
        fullWidth: true,
        isAuto: false,
        custom: "0px",
      },
      height: {
        height: "30px",
      },
      margin: {
        marginTop: "0px",
        marginBottom: "0px",
        marginLeft: "0px",
        marginRight: "0px",
      },
      padding: {
        paddingTop: "4px",
        paddingBottom: "4px",
        paddingLeft: "11px",
        paddingRight: "11px",
      },
      border: {
        borderWidth: "1px",
        borderColor: "#d9d9d9",
        borderStyle: "solid",
        borderRadius: "2px",
      },
      background: {
        backgroundColor: "#fff",
        backgroundImage: {
          url: null,
          size: "contain",
          position: "left top",
          repeat: "no-repeat",
        },
      },
      shadow: {
        horizontal: "0px",
        vertical: "0px",
        blur: "0px",
        spread: "0px",
        color: "#000",
      },
      text: {
        family: "Roboto",
        size: "16px",
        color: "rgba(0,0,0,0.65)",
        alignment: "left",
        weight: "normal",
        style: "normal",
      },
      inputMore: {
        placeholder: null,
        inputType: "text",
        default: "",
        required: false,
      },
      customField: null,
      customClassName: null,
    },
    objects: [],
  }),
  [FORM_OBJECT_TYPES.TEXTAREA]: (id) => ({
    id,
    name: "textarea",
    type: FORM_OBJECT_TYPES.TEXTAREA,
    settings: {
      width: {
        fullWidth: true,
        isAuto: false,
        custom: "0px",
      },
      height: {
        minHeight: "50px",
        height: "50px",
      },
      margin: {
        marginTop: "0px",
        marginBottom: "0px",
        marginLeft: "0px",
        marginRight: "0px",
      },
      padding: {
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "10px",
        paddingRight: "10px",
      },
      border: {
        borderWidth: "1px",
        borderColor: "rgba(0,0,0,0.16)",
        borderStyle: "solid",
        borderRadius: "2px",
      },
      background: {
        backgroundColor: "#fff",
        backgroundImage: {
          url: null,
          size: "contain",
          position: "left top",
          repeat: "no-repeat",
        },
      },
      shadow: {
        horizontal: "0px",
        vertical: "0px",
        blur: "0px",
        spread: "0px",
        color: "#000",
      },
      alignment: "left",
      text: {
        family: "Roboto",
        size: "16px",
        color: "rgba(0,0,0,0.65)",
        alignment: "left",
        weight: "normal",
        style: "normal",
      },
      textareaMore: {
        default: "",
        required: false,
        placeholder: null,
      },
      customField: null,
      customClassName: null,
    },
    objects: [],
  }),
  [FORM_OBJECT_TYPES.SELECT]: (id) => ({
    id,
    name: "select",
    type: FORM_OBJECT_TYPES.SELECT,
    settings: {
      margin: {
        marginTop: "0px",
        marginBottom: "0px",
        marginLeft: "0px",
        marginRight: "0px",
      },
      alignment: "left",
      selectOptionStyle: {
        text: {
          family: "Roboto",
          size: "14px",
          color: "#000",
          alignment: "left",
          weight: "normal",
          style: "normal",
        },
      },
      selectBoxStyle: {
        width: {
          fullWidth: true,
          isAuto: false,
          custom: "0px",
        },
        border: {
          borderColor: "rgba(0,0,0,0.16)",
          borderRadius: "2px",
          borderStyle: "solid",
          borderWidth: "1px",
        },
        background: {
          backgroundColor: "#fff",
        },
        text: {
          family: "Roboto",
          size: "14px",
          color: "#000",
          alignment: "left",
          weight: "normal",
          style: "normal",
        },
      },
      selectMore: {
        options: [
          {
            value: "1",
            label: "Option 01",
          },
          {
            value: "2",
            label: "Option 02",
          },
          {
            value: "3",
            label: "Option 03",
          },
        ],
        placeholder: null,
        default: "1",
        required: false,
      },
      customField: null,
      customClassName: null,
    },
    objects: [],
  }),
  [FORM_OBJECT_TYPES.RADIO]: (id) => ({
    id,
    name: "radio",
    type: FORM_OBJECT_TYPES.RADIO,
    settings: {
      margin: {
        marginTop: "0px",
        marginBottom: "0px",
        marginLeft: "0px",
        marginRight: "0px",
      },
      alignment: "left",
      text: {
        family: "Roboto",
        size: "14px",
        color: "#000",
        weight: "normal",
        style: "normal",
      },
      radioMore: {
        default: false,
        label: "Radio",
      },
      customField: null,
      customClassName: null,
    },
    objects: [],
  }),
  [FORM_OBJECT_TYPES.RADIO_GROUP]: (id) => ({
    id,
    name: "radio group",
    type: FORM_OBJECT_TYPES.RADIO_GROUP,
    settings: {
      margin: {
        marginTop: "0px",
        marginBottom: "0px",
        marginLeft: "0px",
        marginRight: "0px",
      },
      alignment: "left",
      radioGroupOptionStyle: {
        text: {
          family: "Roboto",
          size: "14px",
          color: "#000",
          weight: "normal",
          style: "normal",
        },
      },
      radioGroupMore: {
        options: [
          {
            value: "1",
            label: "Option 01",
          },
          {
            value: "2",
            label: "Option 02",
          },
          {
            value: "3",
            label: "Option 03",
          },
        ],
        default: null,
        required: false,
        optionsDirection: "vertical",
      },
      customField: null,
      customClassName: null,
    },
    objects: [],
  }),
  [FORM_OBJECT_TYPES.HEADING]: (id) => ({
    id,
    name: "heading",
    type: FORM_OBJECT_TYPES.HEADING,
    settings: {
      margin: {
        marginTop: "5px",
        marginBottom: "5px",
        marginLeft: "0px",
        marginRight: "0px",
      },
      padding: {
        paddingTop: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        paddingRight: "0px",
      },
      text: {
        family: "Roboto",
        size: "25px",
        color: "#000",
        alignment: "left",
        weight: "normal",
        style: "normal",
      },
      headingMore: {
        label: "Heading",
      },
      customClassName: null,
    },
    objects: [],
  }),
  [FORM_OBJECT_TYPES.ADDRESS]: (id) => ({
    id,
    name: "address",
    type: FORM_OBJECT_TYPES.ADDRESS,
    settings: {
      margin: {
        marginTop: "0px",
        marginBottom: "0px",
        marginLeft: "0px",
        marginRight: "0px",
      },
      padding: {
        paddingTop: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        paddingRight: "0px",
      },
      addressDetail: {
        width: {
          fullWidth: true,
          isAuto: false,
          custom: "0px",
        },
        height: {
          height: "27px",
        },
        margin: {
          marginTop: "0px",
          marginBottom: "5px",
          marginLeft: "0px",
          marginRight: "0px",
        },
        padding: {
          paddingTop: "5px",
          paddingBottom: "5px",
          paddingLeft: "6px",
          paddingRight: "6px",
        },
        border: {
          borderColor: "rgba(0,0,0,0.16)",
          borderStyle: "solid",
          borderWidth: "1px",
          borderRadius: "0px",
        },
        background: {
          backgroundColor: "#fff",
        },
        text: {
          family: "Roboto",
          color: "#000",
          size: "16px",
          weight: "normal",
          style: "normal",
          alignment: "left",
        },
      },
      addressLevelSelector: {
        width: {
          fullWidth: true,
          isAuto: true,
          custom: "0px",
        },
        height: {
          height: "25px",
        },
        margin: {
          marginTop: "0px",
          marginBottom: "0px",
          marginLeft: "0px",
          marginRight: "0px",
        },
        border: {
          borderColor: "rgba(0,0,0,0.16)",
          borderStyle: "solid",
          borderWidth: "1px",
          borderRadius: "0px",
        },
        background: {
          backgroundColor: "#fff",
        },
        text: {
          family: "Roboto",
          color: "#000",
          size: "16px",
          weight: "normal",
          style: "normal",
          alignment: "left",
        },
      },
      addressLevelLabel: {
        margin: {
          marginTop: "0px",
          marginBottom: "0px",
          marginLeft: "0px",
          marginRight: "0px",
        },
        padding: {
          paddingTop: "0px",
          paddingBottom: "0px",
          paddingLeft: "0px",
          paddingRight: "0px",
        },
        text: {
          family: "Roboto",
          color: "#000",
          size: "13px",
          weight: "normal",
          style: "normal",
          alignment: "left",
        },
        addressLevelLabelDisplayLabel: true,
      },
      addressMore: {
        countryCode: null,
        customFields: {
          addressDetail: null,
          addressLevels: [],
        },
        placeholders: {
          addressDetail: null,
          addressLevels: [],
        },
        required: false,
      },
      customClassName: null,
    },
    objects: [],
  }),
  [FORM_OBJECT_TYPES.NEW_LINE]: (id) => ({
    id,
    name: "new line",
    type: FORM_OBJECT_TYPES.NEW_LINE,
    settings: {
      customClassName: null,
    },
    objects: [],
  }),
  [FORM_OBJECT_TYPES.TEXT]: (id) => ({
    id,
    name: "text",
    type: FORM_OBJECT_TYPES.TEXT,
    settings: {
      margin: {
        marginTop: "5px",
        marginBottom: "5px",
        marginLeft: "0px",
        marginRight: "0px",
      },
      padding: {
        paddingTop: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        paddingRight: "0px",
      },
      text: {
        family: "Roboto",
        size: "13px",
        color: "#000",
        alignment: "left",
        weight: "normal",
        style: "normal",
        shadow: {
          horizontal: "0px",
          vertical: "0px",
          blur: "0px",
          spread: "0px",
          color: "#000",
        },
      },
      textMore: {
        label: "Text",
      },
      customClassName: null,
    },
    objects: [],
  }),
  [FORM_OBJECT_TYPES.IMAGE]: (id) => ({
    id,
    name: "image",
    type: FORM_OBJECT_TYPES.IMAGE,
    settings: {
      width: {
        fullWidth: true,
        isAuto: false,
        custom: "0px",
      },
      height: {
        height: "150px",
      },
      margin: {
        marginTop: "0px",
        marginBottom: "0px",
        marginLeft: "0px",
        marginRight: "0px",
      },
      padding: {
        paddingTop: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        paddingRight: "0px",
      },
      border: {
        borderWidth: "0px",
        borderColor: "#000000",
        borderStyle: "solid",
        borderRadius: "0px",
      },
      shadow: {
        horizontal: "0px",
        vertical: "0px",
        blur: "0px",
        spread: "0px",
        color: "#000",
      },
      alignment: "center",
      image: {
        url: null,
      },
      customClassName: null,
    },
    objects: [],
  }),
  [FORM_OBJECT_TYPES.VIDEO]: (id) => ({
    id,
    name: "video",
    type: FORM_OBJECT_TYPES.VIDEO,
    settings: {
      width: {
        fullWidth: true,
        isAuto: false,
        custom: "0px",
      },
      height: {
        minHeight: "80px",
      },
      margin: {
        marginTop: "0px",
        marginBottom: "0px",
        marginLeft: "0px",
        marginRight: "0px",
      },
      padding: {
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "0px",
        paddingRight: "0px",
      },
      alignment: "center",
      border: {
        borderWidth: "0px",
        borderColor: "#000000",
        borderStyle: "solid",
        borderRadius: "0px",
      },
      shadow: {
        horizontal: "0px",
        vertical: "0px",
        blur: "0px",
        spread: "0px",
        color: "#000",
      },
      video: {
        url: null,
        aspect: "16:9",
        play: {
          loop: false,
          autoPlay: false,
          mute: false,
        },
      },
      customClassName: null,
    },
    objects: [],
  }),
  [FORM_OBJECT_TYPES.CHECKBOX]: (id) => ({
    id,
    name: "checkbox",
    type: FORM_OBJECT_TYPES.CHECKBOX,
    settings: {
      margin: {
        marginTop: "0px",
        marginBottom: "0px",
        marginLeft: "0px",
        marginRight: "0px",
      },
      alignment: "left",
      checkboxGroupOptionStyle: {
        text: {
          family: "Roboto",
          size: "14px",
          color: "#000",
          weight: "normal",
          style: "normal",
        },
      },
      checkboxGroupMore: {
        options: [
          {
            value: "1",
            label: "Option 01",
          },
          {
            value: "2",
            label: "Option 02",
          },
          {
            value: "3",
            label: "Option 03",
          },
        ],
        required: false,
        optionsDirection: "vertical",
      },
      customField: null,
      customClassName: null,
    },
    objects: [],
  }),
};

export const getRequiredFields = (objects = []) => {
  if (isEmpty(objects)) {
    return [];
  }

  const fields = [];

  for (const object of objects) {
    if (get(object, ["settings", "customField"]) && get(object, ["settings", "specials", "required"])) {
      fields.push(get(object, ["settings", "customField"]));
    }

    fields.push(getRequiredFields(get(object, "objects")));
  }

  return flattenDeep(fields);
};

const checkValidFormObjectDataMappings = {
  [FORM_OBJECT_TYPES.INPUT]: (value, object = {}) => {
    const settings = get(object, "settings") || {};
    const type = get(settings, ["inputMore", "inputType"]);
    const required = get(settings, ["inputMore", "required"]);

    if (value === "" && required) {
      return false;
    }

    if (value === "") {
      return true;
    }

    if ((type === INPUT_TYPES.NUMBER || type === INPUT_TYPES.PHONE) && value.search(/^[0-9]+$/) !== 0) {
      return false;
    }

    if (type === INPUT_TYPES.EMAIL && value.search(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/) !== 0) {
      return false;
    }

    return true;
  },
  [FORM_OBJECT_TYPES.ADDRESS]: (value, object = {}) => {
    const settings = get(object, "settings") || {};
    const customFields = get(settings, ["addressMore", "customFields"]) || {};
    const validObj = {
      [customFields.addressDetail]: true,
    };

    (customFields.addressLevels || []).map((cf) => (validObj[cf] = true));

    if (!get(settings, ["addressMore", "required"])) {
      return validObj;
    }

    for (const cf in validObj) {
      if (isEmpty(get(value, cf))) {
        validObj[cf] = false;
      }
    }

    return validObj;
  },
  [FORM_OBJECT_TYPES.SELECT]: (value, object = {}) => {
    const settings = get(object, "settings") || {};

    if (!get(settings, ["selectMore", "required"])) {
      return true;
    }

    if (isEmpty(value)) {
      return false;
    }

    return true;
  },
  [FORM_OBJECT_TYPES.RADIO_GROUP]: (value, object = {}) => {
    const settings = get(object, "settings") || {};

    if (!get(settings, ["radioGroupMore", "required"])) {
      return true;
    }

    if (isEmpty(value)) {
      return false;
    }

    return true;
  },
  [FORM_OBJECT_TYPES.TEXTAREA]: (value, object = {}) => {
    const settings = get(object, "settings") || {};

    if (!get(settings, ["textareaMore", "required"])) {
      return true;
    }

    if (isEmpty(value)) {
      return false;
    }

    return true;
  },
  [FORM_OBJECT_TYPES.RADIO]: (value, object = {}) => {
    const settings = get(object, "settings") || {};

    if (!get(settings, ["radioMore", "required"])) {
      return true;
    }

    if (isNull(value) || isUndefined(value)) {
      return false;
    }

    return true;
  },
};

export const checkValidFormObjectData = (objectType) => (value, object) => {
  const checkFunc = checkValidFormObjectDataMappings[objectType];

  return checkFunc ? checkFunc(value, object) : true;
};

export const checkValidFormObjectsToUse = async (objects = []) => {
  for (const object of objects) {
    if (object.type === FORM_OBJECT_TYPES.ADDRESS) {
      const { countryCode, customFields = {} } = get(object, ["settings", "addressMore"]) || {};

      if (isEmpty(countryCode) || isEmpty(customFields.addressDetail)) {
        return false;
      }

      const levels = (await getListLevel(countryCode)) || [];

      if (levels.length !== (customFields.addressLevels || []).length) {
        return false;
      }

      if ((customFields.addressLevels || []).some((cf) => isEmpty(cf))) {
        return false;
      }
    }

    if (
      object.type !== FORM_OBJECT_TYPES.ADDRESS &&
      FORM_OBJECT_TYPES_CAN_HAVE_USER_VALUE[object.type] &&
      !get(object, ["settings", "customField"])
    ) {
      return false;
    }

    if (!checkValidFormObjectsToUse(object.objects)) {
      return false;
    }
  }

  return true;
};
