export const VIEWS = {
  EXTRA_SMALL: {
    MIN: 0,
    MAX: 575,
  },
  MOBILE: {
    MIN: 576,
    MAX: 767,
  },
  TABLET: {
    MIN: 768,
    MAX: 991,
  },
  DESKTOP: {
    MIN: 992,
    MAX: "auto",
  },
};
