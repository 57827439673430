import React, { useState, useEffect, Fragment } from "react";
import { get } from "lodash";
import styles from "./container.styles.scss";
import { getDefaultView } from "../../utils/others.util";
import { getStyleForSettings } from "../../utils/style.util";
import { FORM_OBJECT_COMPONENT_MAPPINGS } from "../../definitions/form-object-component-mappings.definition";
import { VIEWS } from "../../definitions/views.definition";

const getStyleForEachCell = (cellIndex, view, containerSettings = {}) => {
  const style = {};

  if (view.width <= VIEWS.EXTRA_SMALL.MAX) {
    style.width = "100%";
  }

  if (view.width >= VIEWS.MOBILE.MIN && view.width <= VIEWS.MOBILE.MAX) {
    const columnCounts = get(containerSettings, ["columns", "counts"]);

    if (columnCounts <= 3) {
      style.width = "100%";
    } else {
      if (cellIndex === columnCounts - 1 && columnCounts % 2 !== 0) {
        style.width = "100%";
      } else {
        style.width = "50%";
      }
    }
  }

  if (view.width >= VIEWS.TABLET.MIN) {
    style.width = get(containerSettings, ["columns", "customColumnsWidth", cellIndex]);
  }

  return style;
};

export function BSFormObjectContainer({
  style = {},
  className = "",
  view = getDefaultView(),
  throughProps = {},
  object,
  objectPath = [],
  wrapperComponent = null,
  onObjectData = null,
  onSignalSubmit = null,
  onValidData = null,
  ...props
}) {
  const [objectData, setObjectData] = useState({});
  const [validData, setValidData] = useState({});
  const settings = get(object, "settings") || {};
  const WrapperComponent = wrapperComponent;

  const onChangeObjectData = (data = {}) => setObjectData((previousObjectData) => ({ ...previousObjectData, ...data }));

  const onChangeValidData = (data = {}) => setValidData((previousObjectData) => ({ ...previousObjectData, ...data }));

  useEffect(() => {
    onObjectData && onObjectData(objectData);
  }, [objectData]);

  useEffect(() => {
    onValidData && onValidData(validData);
  }, [validData]);

  const objectsRender = (
    <Fragment>
      {(object.objects || []).map((childObject, index) => {
        const FormObjectComponent = FORM_OBJECT_COMPONENT_MAPPINGS[childObject.type];

        return FormObjectComponent ? (
          <FormObjectComponent
            key={childObject.id}
            wrapperComponent={wrapperComponent}
            style={getStyleForEachCell(index, view, get(object, "settings"))}
            view={view}
            object={childObject}
            objectPath={[...objectPath, index]}
            throughProps={throughProps}
            onObjectData={onChangeObjectData}
            onValidData={onChangeValidData}
            onSignalSubmit={onSignalSubmit}
          />
        ) : null;
      })}
    </Fragment>
  );

  const wrapperStyle = {
    ...getStyleForSettings["styleGroups"](settings, view),
    // ...getStyleForSettings["margin"](
    //   get(object, ["settings", "margin"]) || {},
    //   view
    // ),
    // ...getStyleForSettings["padding"](
    //   get(object, ["settings", "padding"]) || {},
    //   view
    // ),
    // ...getStyleForSettings["background"](
    //   get(object, ["settings", "background"]) || {},
    //   view
    // ),
    // ...getStyleForSettings["border"](
    //   get(object, ["settings", "border"]) || {},
    //   view
    // ),
    // ...getStyleForSettings["shadow"](
    //   get(object, ["settings", "shadow"]) || {},
    //   view
    // ),
    // ...getStyleForSettings["width"](
    //   get(object, ["settings", "width"]) || {},
    //   view
    // ),
    // ...getStyleForSettings["height"](
    //   get(object, ["settings", "height"]) || {},
    //   view
    // ),
    ...(style || {}),
  };
  // console.log("wrapper style", wrapperStyle);
  return wrapperComponent ? (
    <WrapperComponent
      object={object}
      objectPath={objectPath}
      className={`${styles.container} ${className || ""} ${get(object, ["settings", "customClassName"]) || ""}`}
      style={{ ...wrapperStyle, display: "flex" }}
      {...props}
    >
      {objectsRender}
    </WrapperComponent>
  ) : (
    <div
      className={`${styles.container} ${className || ""} ${get(object, ["settings", "customClassName"]) || ""}`}
      style={{ ...wrapperStyle, display: "flex" }}
      {...props}
    >
      {objectsRender}
    </div>
  );
}
