import { get } from "lodash";
import { BROWSERS } from "../definitions/browsers.definition";

export const detectBrowser = () => {
  const userAgent =
    typeof window === "undefined"
      ? ""
      : get(window, ["navigator", "userAgent"]) || "";

  return userAgent.indexOf("Firefox") > -1
    ? BROWSERS.FIREFOX
    : userAgent.indexOf("Chrome") > -1
    ? BROWSERS.CHROME
    : userAgent.indexOf("Safari")
    ? BROWSERS.SAFARI
    : BROWSERS.UNKNOWN;
};

export const getDefaultView = () => {
  return {
    width: typeof window === "undefined" ? 0 : get(window, ["innerWidth"]) || 0,
    height:
      typeof window === "undefined" ? 0 : get(window, ["innerHeight"]) || 0,
  };
};
