import React from "react";
import { get } from "lodash";
import { getDefaultView } from "../../utils/others.util";
import { getStyleForSettings } from "../../utils/style.util";

export function BSFormObjectHeading({
  style = {},
  className = "",
  view = getDefaultView(),
  object,
  objectPath,
  throughProps = {},
  wrapperComponent = null,
  onObjectData = null,
  onSignalSubmit = null,
  onValidData = null,
  ...props
}) {
  const settings = get(object, "settings") || {};
  const WrapperComponent = wrapperComponent;
  const wrapperStyle = {
    ...getStyleForSettings["styleGroups"](settings, view),
    // ...getStyleForSettings["padding"](settings.padding, view),
    // ...getStyleForSettings["margin"](settings.margin, view),
    // ...getStyleForSettings["text"](settings.text, view),
    ...(style || {}),
  };

  return WrapperComponent ? (
    <WrapperComponent
      object={object}
      objectPath={objectPath}
      style={{ width: "100%" }}
      {...props}
    >
      <div
        className={`${className || ""} ${settings.customClassName || ""}`}
        style={{
          width: "100%",
          ...wrapperStyle,
        }}
      >
        {get(settings, ["headingMore", "label"])}
      </div>
    </WrapperComponent>
  ) : (
    <div
      className={`${className || ""} ${settings.customClassName || ""}`}
      style={{
        width: "100%",
        ...wrapperStyle,
      }}
      {...props}
    >
      {get(settings, ["headingMore", "label"])}
    </div>
  );
}
