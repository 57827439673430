import React, { useState, useEffect, useMemo } from "react";
import { Radio } from "antd";
import { get, pick, omit } from "lodash";
import { checkValidFormObjectData } from "../../utils/form-object.util";
import { getStyleForSettings } from "../../utils/style.util";

export function BSFormObjectRadio({
  style = {},
  className = "",
  view = getDefaultView(),
  object,
  objectPath,
  throughProps = {},
  wrapperComponent = null,
  onObjectData = null,
  onClick = null,
  onSignalSubmit = null,
  onValidData = null,
  ...props
}) {
  const customField = useMemo(() => get(object, ["settings", "customField"]), [
    get(object, ["settings", "customField"]),
  ]);
  const [type] = useState(get(object, "type"));
  const [checked, setChecked] = useState(
    get(object, ["settings", "radioMore", "default"]) || false
  );
  const valid = useMemo(() => {
    return checkValidFormObjectData(type)(
      get(object, ["settings", "radioMore", "default"]),
      object
    );
  }, [object, get(object, ["settings", "radioMore", "required"]), checked]);
  const settings = object.settings || {};
  const WrapperComponent = wrapperComponent;

  const onChangeChecked = () => {
    setChecked(true);
    setValid(checkValidFormObjectData(type)(true, object));
  };

  useEffect(() => {
    customField &&
      onObjectData &&
      onObjectData({
        [customField]: checked,
      });
  }, [checked]);

  useEffect(() => {
    customField &&
      onValidData &&
      onValidData({
        [customField]: valid,
      });
  }, [valid]);

  return WrapperComponent ? (
    <WrapperComponent
      object={object}
      objectPath={objectPath}
      className={`${className || ""}`}
      style={{
        ...getStyleForSettings["styleGroups"](
          pick(settings, ["margin", "alignment"]),
          view
        ),
        width: "auto",
        ...pick(style || {}, [
          "margin",
          "marginLeft",
          "marginRight",
          "marginTop",
          "marginBottom",
        ]),
      }}
    >
      <Radio
        className={`${get(settings, ["customClassName"]) || ""}`}
        style={{
          ...getStyleForSettings["text"](settings.text, view),
          ...omit(style || {}, [
            "margin",
            "marginLeft",
            "marginRight",
            "marginTop",
            "marginBottom",
          ]),
        }}
        checked={checked}
        onClick={(e) => {
          onChangeChecked(e);
          onClick && onClick(e);
        }}
        {...props}
      >
        {get(settings, ["radioMore", "label"])}
      </Radio>
    </WrapperComponent>
  ) : (
    <Radio
      className={`${get(settings, ["customClassName"]) || ""} ${
        className || ""
      }`}
      style={{
        ...getStyleForSettings["styleGroups"](settings, view),
        ...(style || {}),
      }}
      checked={checked}
      onClick={(e) => {
        onChangeChecked(e);
        onClick && onClick(e);
      }}
      {...props}
    >
      {get(settings, ["radioMore", "label"])}
    </Radio>
  );
}
