import React, { useState, useEffect, useMemo } from "react";
import { get, pick, omit } from "lodash";
import { Input } from "antd";
import { checkValidFormObjectData } from "../../utils/form-object.util";
import { getStyleForSettings } from "../../utils/style.util";
import styles from "./textarea.styles.scss";

export function BSFormObjectTextarea({
  style = {},
  className = "",
  view = getDefaultView(),
  object,
  objectPath,
  wrapperComponent = null,
  throughProps = {},
  onObjectData = null,
  onSignalSubmit = null,
  onValidData = null,
  ...props
}) {
  const customField = useMemo(() => get(object, ["settings", "customField"]), [get(object, ["settings", "customField"])]);
  const [type] = useState(get(object, "type"));
  const [value, setValue] = useState(get(object, ["settings", "textareaMore", "default"]) || null);
  const valid = useMemo(() => {
    return checkValidFormObjectData(type)(value, object);
  }, [object, get(object, ["settings", "textareaMore", "required"]), value]);

  const settings = get(object, "settings") || {};
  const WrapperComponent = wrapperComponent;

  const onChangeValue = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    customField && onObjectData && onObjectData({ [customField]: value });
  }, [value]);

  useEffect(() => {
    customField && onValidData && onValidData({ [customField]: valid });
  }, [valid]);

  return WrapperComponent ? (
    <WrapperComponent
      object={object}
      objectPath={objectPath}
      className={`${className || ""}`}
      style={{
        height: "auto",
        ...getStyleForSettings["styleGroups"](pick(settings, ["margin", "alignment", "width"]), view),
        ...pick(style || {}, ["margin", "marginTop", "marginBottom", "marginLeft", "marginRight", "width", "minWidth", "maxWidth"]),
      }}
    >
      <Input.TextArea
        className={`${get(settings, ["customClassName"]) || ""} ${valid ? "" : styles.inValid}`}
        style={{
          ...getStyleForSettings["styleGroups"](omit(settings, ["margin", "alignment", "width"]), view),
          ...omit(style || {}, ["margin", "marginTop", "marginBottom", "marginLeft", "marginRight", "width", "minWidth", "maxWidth"]),
        }}
        placeholder={get(settings, ["textareaMore", "placeholder"]) || ""}
        value={value}
        onChange={onChangeValue}
        autoSize={true}
        {...props}
      />
    </WrapperComponent>
  ) : (
    <Input.TextArea
      className={`${className || ""} ${get(object, ["settings", "customClassName"]) || ""} ${valid ? "" : styles.inValid}`}
      style={{
        ...getStyleForSettings["styleGroups"](settings, view),
        ...(style || {}),
      }}
      placeholder={get(settings, ["textareaMore", "placeholder"]) || ""}
      value={value}
      onChange={onChangeValue}
      autoSize={true}
      {...props}
    />
  );
}
