import { FORM_OBJECT_TYPES } from "./form-object-types.definition";
import {
  BSFormObjectContainer,
  BSFormObjectCell,
  BSFormObjectInput,
  BSFormObjectButton,
  BSFormObjectLabel,
  BSFormObjectSelect,
  BSFormObjectRadio,
  BSFormObjectRadioGroup,
  BSFormObjectTextarea,
  BSFormObjectAddress,
  BSFormObjectHeading,
  BSFormObjectNewLine,
  BSFormObjectText,
  BsFormObjectImage,
  BsFormObjectVideo,
  BSFormObjectCheckbox
} from "../components";

export const FORM_OBJECT_COMPONENT_MAPPINGS = {
  [FORM_OBJECT_TYPES.CONTAINER]: BSFormObjectContainer,
  [FORM_OBJECT_TYPES.CELL]: BSFormObjectCell,
  [FORM_OBJECT_TYPES.IMAGE]: BsFormObjectImage,
  [FORM_OBJECT_TYPES.INPUT]: BSFormObjectInput,
  [FORM_OBJECT_TYPES.SELECT]: BSFormObjectSelect,
  [FORM_OBJECT_TYPES.BUTTON]: BSFormObjectButton,
  [FORM_OBJECT_TYPES.CHECKBOX]: BSFormObjectCheckbox,
  [FORM_OBJECT_TYPES.HEADING]: BSFormObjectHeading,
  [FORM_OBJECT_TYPES.RADIO]: BSFormObjectRadio,
  [FORM_OBJECT_TYPES.RADIO_GROUP]: BSFormObjectRadioGroup,
  [FORM_OBJECT_TYPES.TEXTAREA]: BSFormObjectTextarea,
  [FORM_OBJECT_TYPES.USER_UPLOADER]: null,
  [FORM_OBJECT_TYPES.LABEL]: BSFormObjectLabel,
  [FORM_OBJECT_TYPES.ADDRESS]: BSFormObjectAddress,
  [FORM_OBJECT_TYPES.NEW_LINE]: BSFormObjectNewLine,
  [FORM_OBJECT_TYPES.TEXT]: BSFormObjectText,
  [FORM_OBJECT_TYPES.VIDEO]: BsFormObjectVideo
};
