export const FORM_OBJECT_TYPES = {
  CONTAINER: "container",
  CELL: "cell",
  HEADING: "heading",
  BUTTON: "button",
  IMAGE: "image",
  INPUT: "input",
  TEXTAREA: "textarea",
  SELECT: "select",
  RADIO: "radio",
  RADIO_GROUP: "radio-group",
  CHECKBOX: "checkbox",
  USER_UPLOADER: "user-uploader",
  LABEL: "label",
  ADDRESS: "address",
  NEW_LINE: "new-line",
  TEXT: "text",
  VIDEO: "video",
  SEPARATOR: "separator",
  CARD: "card",
};

export const FORM_OBJECT_TYPES_CAN_HAVE_USER_VALUE = {
  [FORM_OBJECT_TYPES.INPUT]: true,
  [FORM_OBJECT_TYPES.ADDRESS]: true,
  [FORM_OBJECT_TYPES.CHECKBOX]: true,
  [FORM_OBJECT_TYPES.RADIO]: true,
  [FORM_OBJECT_TYPES.RADIO_GROUP]: true,
  [FORM_OBJECT_TYPES.SELECT]: true,
  [FORM_OBJECT_TYPES.TEXTAREA]: true,
  [FORM_OBJECT_TYPES.USER_UPLOADER]: true,
  [FORM_OBJECT_TYPES.LABEL]: false,
  [FORM_OBJECT_TYPES.HEADING]: false,
  [FORM_OBJECT_TYPES.NEW_LINE]: false,
  [FORM_OBJECT_TYPES.BUTTON]: false,
  [FORM_OBJECT_TYPES.TEXT]: false,
  [FORM_OBJECT_TYPES.IMAGE]: false,
  [FORM_OBJECT_TYPES.VIDEO]: false,
  [FORM_OBJECT_TYPES.SEPARATOR]: false,
  [FORM_OBJECT_TYPES.CARD]: false
};
