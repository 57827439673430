import { FORM_OBJECT_TYPES } from "./definitions/form-object-types.definition";
import { DEFAULT_SETTINGS } from "./definitions/default-setting.definition";
import { FORM_OBJECT_COMPONENT_MAPPINGS } from "./definitions/form-object-component-mappings.definition";
import { INPUT_TYPES } from "./definitions/input-types.definition";
import { formObjectGenerators, checkValidFormObjectData, checkValidFormObjectsToUse } from "./utils/form-object.util";

import {
  getStyleForBackground,
  getStyleForBorder,
  getStyleForFormat,
  getStyleForHeight,
  getStyleForMargin,
  getStyleForPadding,
  getStyleForShadow,
  getStyleForWidth,
} from "./utils/style.util";

export default {
  FORM_OBJECT_TYPES,
  DEFAULT_SETTINGS,
  INPUT_TYPES,
  FORM_OBJECT_COMPONENT_MAPPINGS,
  getStyleForBackground,
  getStyleForBorder,
  getStyleForFormat,
  getStyleForHeight,
  getStyleForMargin,
  getStyleForPadding,
  getStyleForShadow,
  getStyleForWidth,
  formObjectGenerators,
  checkValidFormObjectData,
  checkValidFormObjectsToUse,
};
