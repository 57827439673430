import React from "react";
import { get } from "lodash";

export function BSFormObjectNewLine({
  style = {},
  className = "",
  view = getDefaultView(),
  object,
  objectPath,
  throughProps = {},
  wrapperComponent = null,
  onObjectData = null,
  onSignalSubmit = null,
  onValidData = null,
  ...props
}) {
  const settings = get(object, "settings") || {};
  const WrapperComponent = wrapperComponent;

  return WrapperComponent ? (
    <WrapperComponent
      className={`${className || ""}`}
      style={{ ...(style || {}), width: "100%" }}
      object={object}
      objectPath={objectPath}
      {...props}
    >
      <div
        className={`${get(settings, "customClassName") || ""}`}
        style={{
          width: "100%",
          height: "5px",
          background: "transparent",
        }}
      />
    </WrapperComponent>
  ) : (
    <div
      className={`${className || ""} ${get(settings, "customClassName") || ""}`}
      style={{
        ...(style || {}),
        width: "100%",
        height: "5px",
        background: "transparent",
      }}
      {...props}
    />
  );
}
