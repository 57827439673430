import React, { useState, useEffect, Fragment, useRef } from "react";
import { get } from "lodash";
import styles from "./cell.styles.scss";
import { getDefaultView } from "../../utils/others.util";
import { getStyleForSettings } from "../../utils/style.util";
import {
  FORM_OBJECT_COMPONENT_MAPPINGS,
} from "../../definitions/form-object-component-mappings.definition";

export function BSFormObjectCell({
  style = {},
  className = "",
  view = getDefaultView(),
  object,
  objectPath = [],
  wrapperComponent = null,
  throughProps = {},
  onObjectData = null,
  onSignalSubmit = null,
  onValidData = null,
  ...props
}) {
  const [objectData, setObjectData] = useState({});
  const [validData, setValidData] = useState({});

  const settings = get(object, "settings") || {};
  const WrapperComponent = wrapperComponent;

  const onChangeObjectData = (data = {}) => {
    setObjectData((previousObjectData) => ({ ...previousObjectData, ...data }));
  };

  const onChangeValidData = (data = {}) =>
    setValidData((previousObjectData) => ({ ...previousObjectData, ...data }));

  useEffect(() => {
    onObjectData && onObjectData(objectData);
  }, [objectData]);

  useEffect(() => {
    onValidData && onValidData(validData);
  }, [validData]);

  const objectsRender = (
    <Fragment>
      {(object.objects || []).map((childObject, index) => {
        const FormObjectComponent =
          FORM_OBJECT_COMPONENT_MAPPINGS[childObject.type];

        return FormObjectComponent ? (
          <FormObjectComponent
            key={childObject.id}
            view={view}
            wrapperComponent={wrapperComponent}
            object={childObject}
            objectPath={[...objectPath, index]}
            throughProps={throughProps}
            onObjectData={onChangeObjectData}
            onSignalSubmit={onSignalSubmit}
            onValidData={onChangeValidData}
          />
        ) : null;
      })}
    </Fragment>
  );

  const wrapperStyle = {
    ...getStyleForSettings["styleGroups"](settings, view),
    ...(settings.contentAlignment
      ? {
          alignItems: settings.contentAlignment.horizontal,
          justifyContent: settings.contentAlignment.vertical,
        }
      : {}),
    ...(style || {}),
  };
  return wrapperComponent ? (
    <WrapperComponent
      object={object}
      objectPath={objectPath}
      className={`${styles.container} ${className || ""} ${
        get(settings, ["customClassName"]) || ""
      }`}
      style={{ ...wrapperStyle }}
      {...props}
    >
      {objectsRender}
    </WrapperComponent>
  ) : (
    <div
      className={`${styles.container} ${className || ""} ${
        get(object, ["settings", "customClassName"]) || ""
      }`}
      style={{ ...wrapperStyle }}
      {...props}
    >
      {objectsRender}
    </div>
  );
}
