import React, { useState, useEffect, useMemo } from "react";
import { get, omit, pick } from "lodash";
import { Select } from "antd";
import { checkValidFormObjectData } from "../../utils/form-object.util";
import { getStyleForSettings } from "../../utils/style.util";
import styles from "./select.styles.scss";
import "./overwrite.css";

const { Option } = Select;

export function BSFormObjectSelect({
  style = {},
  className = "",
  view = getDefaultView(),
  object,
  objectPath,
  wrapperComponent = null,
  throughProps = {},
  onObjectData = null,
  onSignalSubmit = null,
  onValidData = null,
  ...props
}) {
  const customField = useMemo(() => get(object, ["settings", "customField"]), [
    get(object, ["settings", "customField"]),
  ]);
  const [type] = useState(get(object, "type"));
  const [value, setValue] = useState(
    get(object, ["settings", "selectMore", "default"]) || null
  );
  const valid = useMemo(() => {
    return checkValidFormObjectData(type)(value, object);
  }, [object, get(object, ["settings", "selectMore", "required"]), value]);

  const settings = get(object, "settings") || {};
  const WrapperComponent = wrapperComponent;

  const onChangeSelection = (newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    customField && onObjectData && onObjectData({ [customField]: value });
  }, [value]);

  useEffect(() => {
    customField && onValidData && onValidData({ [customField]: valid });
  }, [valid]);

  return WrapperComponent ? (
    <WrapperComponent
      object={object}
      objectPath={objectPath}
      className={`${className || ""} ${get(settings, "customClassName") || ""}`}
      style={{
        display: "inline-block",
        ...getStyleForSettings["styleGroups"](
          pick(settings, ["margin", "alignment"])
        ),
        ...getStyleForSettings["width"](settings.selectBoxStyle.width, view),
        ...omit(style || {}, ["width"]),
      }}
    >
      <Select
        className={`bs-form-object-components-select ${
          valid ? "" : styles.inValid
        } ${className || ""} ${get(settings, "customClassName") || ""}`}
        style={{
          ...getStyleForSettings["styleGroups"](
            omit(settings, ["alignment", "margin", "selectBoxStyle"])
          ),
          ...getStyleForSettings["selectBoxStyle"](
            settings.selectBoxStyle,
            view,
            ["width"]
          ),
          ...(style || {}),
          width: "100%",
        }}
        dropdownClassName={styles.dropdown}
        dropdownStyle={{ width: "auto", maxWidth: "auto" }}
        placeholder={get(settings, ["selectMore", "placeholder"]) || ""}
        value={value || undefined}
        onChange={onChangeSelection}
        {...props}
      >
        {(get(settings, ["selectMore", "options"]) || []).map((option) => (
          <Option
            key={option.value}
            value={option.value}
            style={{
              ...getStyleForSettings["optionStyle"](settings.optionStyle, view),
            }}
          >
            {option.label}
          </Option>
        ))}
      </Select>
    </WrapperComponent>
  ) : (
    <Select
      className={`bs-form-object-components-select ${
        valid ? "" : styles.inValid
      } ${className || ""} ${get(settings, "customClassName") || ""}`}
      style={{
        ...getStyleForSettings["styleGroups"](settings, view),
        ...(style || {}),
        display: "flex",
        alignItems: "center",
      }}
      dropdownClassName={styles.dropdown}
      dropdownStyle={{ width: "auto", maxWidth: "auto" }}
      placeholder={get(settings, ["selectMore", "placeholder"]) || ""}
      value={value || undefined}
      onChange={onChangeSelection}
      {...props}
    >
      {(get(settings, ["selectMore", "options"]) || []).map((option) => (
        <Option
          key={option.value}
          value={option.value}
          style={{
            ...getStyleForSettings["optionStyle"](settings.optionStyle, view),
          }}
        >
          {option.label}
        </Option>
      ))}
    </Select>
  );
}
