export const DEFAULT_SETTINGS = {
  columns: {
    counts: 1,
    spacing: "0px",
    equalColumnsWidth: true,
    customColumnsWidth: ["100%"],
  },
  padding: {
    paddingTop: "0px",
    paddingBottom: "0px",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  margin: {
    marginTop: "0px",
    marginBottom: "0px",
    marginLeft: "0px",
    marginRight: "0px",
  },
  height: {
    minHeight: "50px",
    height: "50px",
  },
  width: {
    fullWidth: true,
    isAuto: false,
    custom: "0px",
  },
  border: {
    borderWidth: "0px",
    borderColor: "#000",
    borderStyle: "solid",
    borderRadius: "0px",
  },
  background: {
    backgroundColor: "#fff",
    backgroundImage: {
      url: null,
      size: "contain",
      position: "left center",
      repeat: "no-repeat",
    },
  },
  shadow: {
    horizontal: "0px",
    vertical: "0px",
    blur: "0px",
    spread: "0px",
    color: "#000",
  },
  text: {
    family: "Roboto",
    color: "#000",
    size: "13px",
    style: "normal",
    alignment: "left",
    letterSpacing: "2px",
    lineHeight: "15px",
    shadow: {
      horizontal: "0px",
      vertical: "0px",
      blur: "0px",
      spread: "0px",
      color: "#000",
    },
  },
  alignment: "left",
  contentAlignment: {
    horizontal: "center",
    vertical: "center",
  },
};
